import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles<Theme>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: '50vw',
			height: '50vh',
		},

		errorButton: {
			backgroundColor: theme.palette.red.light,
			color: theme.palette.white.light,

			'&:hover': { backgroundColor: theme.palette.red.light },
		},

		buttonContainer: {
			marginLeft: theme.spacing(3),
			marginTop: theme.spacing(1),

			'& svg': {
				width: theme.spacing(2.5),
				height: theme.spacing(2.5),
			},
		},

		icon: {},
	}),
	{ name: `Dashboard_TopBar_FinalForm` }
);
