import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		EndOfDay: {
			save: 'Zapisz',
			delete: 'Usuń',
		},
	},
	en: {
		EndOfDay: {
			save: 'Save',
			delete: 'Delete',
		},
	},
};
