import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				position: 'relative',
				minWidth: theme.spacing(21),
			},

			// text: {
			// 	textOverflow: 'ellipsis',
			// 	width: theme.spacing(21),
			// 	overflow: 'hidden',
			// 	whiteSpace: 'nowrap',
			// 	wordBreak: 'break-word',
			// 	overflowWrap: 'break-word',
			// },

			text: {
				width: theme.spacing(21),
				overflow: 'hidden',
				wordBreak: 'break-word',
				overflowWrap: 'break-word',
			},

			endOfWorkFormText: {
				wordBreak: 'break-word',
				width: theme.spacing(36),
			},

			tooltipText: {
				color: theme.palette.white.main,
			},

			title: {
				width: '100px',
				fontSize: '14px',
			},

			selectError: {
				'& .select__control': {
					border: '2px solid red',

					'&--is-focused': {
						border: '2px solid red',
					},
				},
			},

			starIcon: {
				position: 'absolute',
				top: '0px',
				left: '-10px',
				color: 'red',
				fontSize: '10px',
			},

			errorText: {
				color: theme.palette.red.main,
			},

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.5),
			},

			valueContainer: {
				position: 'relative',
				padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(0)} `,
			},

			multiValueContainer: {
				'& .select__value-container': {
					padding: '1px 0px 1px 8px !important',
				},
			},

			tagText: {
				maxWidth: '60px',
			},

			counter: {
				padding: theme.spacing(0.3),
				backgroundColor: '#5c6277',
				color: 'white',
				borderRadius: '3px',
				marginLeft: theme.spacing(1),
			},

			icon: {
				fontSize: '15px',
			},

			counterText: {
				color: 'white',
				fontSize: '15px',
			},
		};
	},
	{
		name: `IssueModalNew.IssueModalContent.IssueDetailsPage.MainIssueData.DependentInputs.IssueFormSection.IssuePropertyTitle`,
	}
);
