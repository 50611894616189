import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { IconButton } from '@mui/material';

import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';
import { Button, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { checkListValue_Create, fetch_formAnswers, fetch_formByUser } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils';

import { Form } from './Form';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const EndOfDay = (props: Props): JSX.Element => {
	const { onClose, onSave: onSaveListener, modalData: fetchData, isEdit = false } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const { t } = useTrans('EndOfDay');

	const formState: IForm = useSelector((state: IReducerState) => state?.DashBoard?.EndOfWork.form);
	const forms: IForm[] = useSelector((state: IReducerState) => state?.DashBoard?.EndOfWork.forms);

	const values: { [x: number]: ICheckListValue[] } = useSelector(
		(state: IReducerState) => state?.DashBoard?.EndOfWork.values
	);

	const onSave = () => {
		const data = _.flatMap(Object.values(values));
		dispatch(checkListValue_Create(data, formState?.Id)).then(onSaveListener);
	};

	useEffect(() => {
		if (fetchData) {
			dispatch(fetch_formAnswers(fetchData));
		} else dispatch(fetch_formByUser());
	}, []);

	return (
		<div className={clsx(classes.root)}>
			{forms?.map((form: IForm) => (
				<Form key={form?.Id} isEdit={isEdit} fetchData={fetchData} form={form} />
			))}
			<VisibilityControl condition={isEdit}>
				<div className={classes.buttonContainer}>
					<Button variant={Resources.GlobalResources.contained} onClick={onSave}>
						<SaveAsIcon className={classes.icon} />
						{t('save')}
					</Button>
					<Button onClick={onClose} className={classes.errorButton}>
						<DeleteForeverIcon className={classes.icon} />
						{t('delete')}
					</Button>
				</div>
			</VisibilityControl>
		</div>
	);
};

export default EndOfDay;
