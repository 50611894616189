import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDownloader from 'react-use-downloader';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Badge, Grid, Menu, MenuItem, Tooltip } from '@mui/material';

import { IAttachment, IIssueAttachment } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';
import { errorVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { attachment_show } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Attachment = (props: Props) => {
	const { issue } = props;
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();
	const { tString } = useTrans('IssueList.TileComponents.AttachmentCount');
	const snackBar = useSnackbar();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [downloadButton, setDownloadButton] = useState<HTMLElement>();
	const { download, error } = useDownloader();

	useEffect(() => {
		if (downloadButton) {
			const button = document.getElementById(Resources.downloadButtonId);
			button?.click();
			setDownloadButton(undefined);
		}
	}, [downloadButton]);

	useEffect(() => {
		if (error) snackBar.enqueueSnackbar(tString('errorDownloading'), errorVariant);
	}, [error]);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const onClick = (issueAttachment: IIssueAttachment) => {
		downloadWrapper(issueAttachment?.Attachment as IAttachment);
	};

	const downloadWrapper = (attachment: Partial<IAttachment>) => {
		const id = attachment?.Id;
		dispatch(attachment_show(id?.toString() as string)).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			download(url, attachment?.FileName as string);
		});
	};

	const onClickAttachmentIcon: MouseEventHandler<HTMLDivElement> = e => {
		if (e) setAnchorEl(e.currentTarget);
	};

	return useMemo(() => {
		return (
			<div
				className={clsx(
					!!issue?.AttachmentCount && issue?.AttachmentCount > 0
						? classes.paddingRight
						: classes.paddingRightEmpty
				)}
			>
				<VisibilityControl condition={!!issue?.AttachmentCount && issue?.AttachmentCount > 0} alternative={'-'}>
					<Grid
						onClick={onClickAttachmentIcon}
						container
						className={clsx(classes.flexRow, classes.badgeGrid)}
					>
						<Tooltip title={tString('attachmentCount')}>
							<Badge
								classes={{
									badge: clsx(classes.badge),
								}}
								badgeContent={issue?.AttachmentCount ?? 0}
							>
								<AttachFileIcon className={classes.transformBadge} />
							</Badge>
						</Tooltip>
					</Grid>
					<VisibilityControl condition={open}>
						<Menu
							id={Resources.menuId}
							open={open}
							anchorEl={anchorEl}
							MenuListProps={{ [Resources.ariaLabelledby]: Resources.basicButton }}
							className={classes.menu}
							onClose={handleClose}
						>
							{issue?.IssueAttachment?.map(x => {
								return (
									<MenuItem
										onClick={() => onClick(x)}
										className={classes.menuItem}
										key={Resources.key(x?.Id)}
									>
										{x?.Attachment?.FileName}
									</MenuItem>
								);
							})}
						</Menu>
					</VisibilityControl>
				</VisibilityControl>
			</div>
		);
	}, [open, issue?.IssueAttachment]);
};
